@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Estilo para navegadores Webkit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 14px; /* Ancho de la barra */
  }
  
  ::-webkit-scrollbar-track {
    background: #EFEFEE; /* Color del fondo */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #2d2d2d; /* Color de la barra */
    border-radius: 6px; /* Redondeo de la barra */
    border: 3px solid #EFEFEE; /* Espacio entre la barra y el fondo */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    /* background-color: #191919; Color al pasar el cursor */
    background-color: #0b55f5; /* Color al pasar el cursor */
  }

  .animate__animated{
    animation-duration: 1000ms;
  }
  